import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'file' }
  },  {
    path: '/user',
    name: 'user',
    component: ()=>import(/* webpackChunkName: "Users" */'../views/Users.vue')
  },{
    path: '/user/add',
    name: 'userAdd',
    component: ()=>import(/* webpackChunkName: "UserAdd" */'../views/UserAdd.vue')
  },{
    path: '/user/:id/edit',
    name: 'userEdit',
    component: ()=>import(/* webpackChunkName: "UserEdit" */'../views/UserEdit.vue')
  },{
    path: '/user/:id/password',
    name: 'changePassword',
    component: ()=>import(/* webpackChunkName: "ChangePassword" */'../views/ChangePassword.vue')
  },  {
    path: '/file',
    name: 'file',
    component: ()=>import(/* webpackChunkName: "Files" */'../views/Files.vue')
  },  {
    path: '/file/add',
    name: 'fileADd',
    component: ()=>import(/* webpackChunkName: "FileAdd" */'../views/FileAdd.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

//preloading
addEventListener('load', ()=>{
  for (const route of routes) {
    route.component()
  }
})