import InlineRestClient from "inline-rest-client"

export const Api = new InlineRestClient({
    url: '/backend/',
    defaultMethod: 'post',
    parameterType: 'json',
    withCredentials: true
})
export const ApiMultipart = new InlineRestClient({
    url: '/backend/',
    defaultMethod: 'post',
    parameterType: 'multipart',
    withCredentials: true
})